import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import { Section, SectionTitle, SectionDescription, SectionHeader, SectionPageTitle,  BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultButton from "../components/button/DefaultButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';
import BuildingRoofStyle from '../sections/Product/BuildingRoofStyle'

const CtaWrap = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	z-index: 10;
	max-width: 960px;
	width: 100%;
	margin: auto;
	padding: 41px 34px;
	background: white;
	border: 1px solid #DDDDDD;
	h3 {
		margin-bottom: 20px;
	}
	& div:nth-child(1) {
		width: calc(100% - 200px);
	}
	@media(max-width: 768px) {
		display: block;
		& div {
			width: 100% !important;
		}
	}
`

const ButtonWrap = styled.div`
	width: 200px;
	text-align: right;
	& .btn{
		padding: 20px 30px;
		font-size: 20px;
	}
	@media(max-width: 768px) {
		text-align: left;
	}
`
const MetalBuildingRoofStylesPage = ({ data, location }) => {
	const pageData=data.contentfulMetalBuildingRoofsPage
	const PostContent = HTMLContent || Content;
	return(
		<Layout location={location}>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription}/>
      <SectionHeader pt="140px" pb="90px" xpt="140px" xpb="60px">
        <div className="container">
          <BreadCrumb><Link to='/'>Home</Link>/<span>Building Roof Styles</span></BreadCrumb>         
          <SectionPageTitle mt="30px" mb="15px" textAlign="center">{pageData.title}</SectionPageTitle>
          <SectionDescription maxWidth="1120px" >
            <PostContent
              content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)}
            />
          </SectionDescription>       
        </div>
      </SectionHeader>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#fff">
        <div className="container">            
          <SectionTitle mb="20px">{pageData.roofsSectionTitle}</SectionTitle>
        </div>
        <BuildingRoofStyle data={pageData.metalRoofs} />
        <CtaWrap>
        	<div>
        		<h3>{pageData.ctaTitle}</h3>
        		<p>{pageData.ctaDescription.ctaDescription}</p>
        	</div>
        	<ButtonWrap>
						<a href="tel:8776005980">
							<DefaultButton icon={<PhoneIcon />} text="Call Now" />
						</a>
					</ButtonWrap>
        </CtaWrap>
      </Section>
    </Layout>
	)
}

export default MetalBuildingRoofStylesPage

export const pageQuery = graphql`
  query MetalBuildingRoofStylesPageQuery {
  	contentfulMetalBuildingRoofsPage {
	    metaTitle
	    metaDescription
	    title
	    heroDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    roofsSectionTitle
	    metalRoofs {
	      roofName
	      metalRoofName
	      metalRoofDescription {
	        childMarkdownRemark {
	          html
	        }
	      }
	      metalRoofBenefits {
	        content
	      }
	      metalRoofImage {
	        fluid {
	          aspectRatio
	          base64
	          src
	          srcSet
	          srcSetWebp
	          srcWebp
	        }
	      }
	    }
	    ctaTitle
	    ctaDescription {
	      ctaDescription
	    }
	  }
  }
 `
