import React from 'react'
import styled from "styled-components"
import Img from 'gatsby-image'
import BreakpointUp from "../../components/Media/BreakpointUp"
import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../../components/Content';

const SectionWrap = styled.div`
  margin-top:90px;
`

const SectionItem = styled.div`  
  display:flex;	
  flex-wrap:wrap;
  padding-right: calc((100% - 1350px)/2);    
  align-items:center;
  ${BreakpointUp.lg`        
    &:nth-child(even){
      padding-right: 0;
      padding-left: calc((100% - 1350px)/2);  
      flex-direction: row-reverse;
      & .card{
        &:before{
          left:auto;
          right:-50%;
        }
      }
      & .SectionFigure{ 
        padding:0 100px 90px 45px;
      }
    }
  `}
`

const SectionFigure = styled.div`  
  width:100%;
  position:relative;
  padding:0 30px 90px 30px;
  ${BreakpointUp.sm`  
    padding:0 45px 90px 100px;
  `}
  ${BreakpointUp.lg`  
    flex: 0 0 50%;
    max-width: 50%;
	`}
`


const SectionContent = styled.div`  
position:relative;
width:100%;
  padding:0 30px 90px 30px;
  ${BreakpointUp.sm`  
    padding:0 45px 90px 100px;
  `}
  ${BreakpointUp.lg`  
    flex: 0 0 50%;
    max-width: 50%;
	`}
    & h3{
      margin-bottom: 15px;
      color:#020304;
      font-size: 28px;
      line-height: 38px;
      ${BreakpointUp.md`  
        font-size: 34px;
        line-height: 50px;
      `}
    }
`
const Card = styled.div`
	display:flex;
	flex-direction:column;
  position:relative;
  &:before{
    content: '';
    left: -50%;
    width: 100%;
    background-color: #F6F6F6;
    position: absolute;
    height: calc(100% + 100px);
    top: -50px;
  }
`
const CardFigure = styled.div`	
	position:relative;
	width:100%;
  height: 400px;
	& .gatsby-image-wrapper{
		height:100%;
	}
  @media(max-width: 560px) {
    height: 200px;
  }
`
const CardTip = styled.div`
	background-color:${props=>props.bgColor};
	color:${props=>props.color};
	font-size:14px;
	line-height:18px;
	font-weight:700;
	padding:5px 15px;
	position:absolute;
  left:${props=>props.left};  
  right:${props=>props.right};  
	z-index:1;
  top:50px; 
  
`
CardTip.defaultProps = {
	color:"#2F9800",
  bgColor:"rgba(47, 152, 0, 0.14)"
}

const BuildingRoofStyle = ({ data }) => {
  const PostContent = HTMLContent || Content;
	return(
		<SectionWrap>
      {
        data.map((item, i)=> {
          return(
            <SectionItem>
              <SectionFigure className="SectionFigure">
                <Card className="card">
                  {item.roofName==="Vertical Roof"? (<CardTip right="0" color="#2F9800" bgColor="#d5eacc">Best</CardTip>):(<></>)}
                  {item.roofName==="A-Frame Roof"? (<CardTip  color="#216ABC" bgColor="#d3e1f2">Better</CardTip>):(<></>)}
                  {item.roofName==="Regular Roof"? (<CardTip right="0" color="#DE5600" bgColor="#f8ddcc">Good</CardTip>):(<></>)}
                  <CardFigure>
                    <Img fluid={item.metalRoofImage.fluid} />
                  </CardFigure>
                </Card>
              </SectionFigure>          
              <SectionContent>
                <h3>{item.metalRoofName}</h3>
                <PostContent
                  content={generateHTML(item.metalRoofDescription.childMarkdownRemark.html)}
                />
                <h4>Benefits</h4><br/>
                {
                  item.metalRoofBenefits.map(item=>{
                    return(
                      <li>{item.content}</li>
                    )
                  })
                }
              </SectionContent>
            </SectionItem>     
          )
        })
      }
    </SectionWrap>
	)
}

export default BuildingRoofStyle